<template>
  <div class="edit-user">
    <div class="title">用户编辑</div>
    <div class="cell">
      <el-form
        ref="form"
        size="mini"
        style="width: 500px"
        :model="form"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="用户名">
          <span style="font-size: 12px">{{ form.realname }}</span>
        </el-form-item>
        <el-form-item label="登录账号">
          <span style="font-size: 12px">{{ form.username }}</span>
        </el-form-item>
        <el-form-item label="所属角色" prop="role_id">
          <el-select v-model="form.role_id" placeholder="请选择角色">
            <el-option
              v-for="item in tableData"
              :key="item.id"
              :label="item.role_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确认</el-button>
          <el-button @click="handleBack">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getAllRole, editU } from "@/api/admin.js";
export default {
  data() {
    return {
      form: {
        realname: "",
        username: "",
        role_id: "",
      },
      tableData: [],
      rules: {
        role_id: { required: true, message: "请选择角色", trigger: "change" },
      },
    };
  },
  created() {
    this.getRoleList();
    this.form = JSON.parse(sessionStorage.ADMIN_USER_INFO);
  },
  methods: {
    // 获取列表
    getRoleList() {
      getAllRole().then((res) => {
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          editU(this.form).then((res) => {
            if (res.code == 1) {
              this.$message.success("编辑用户成功");
              this.$router.replace("userList");
            }
          });
        }
      });
    },
    handleBack() {
      this.$router.replace("userList");
    },
  },
};
</script>
<style lang="less" >
.edit-user {
  .title {
    font-size: 18px;
    padding-bottom: 20px;
  }
  .cell {
    background: #fff;
    border-radius: 4px;
    padding: 20px;

    .el-form-item__label {
      font-size: 12px;
    }
  }
}
</style>